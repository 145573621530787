var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "start-a-business" },
        [
          _c("progress-steps", {
            attrs: {
              subtitle: _vm.subtitle,
              "progress-status": _vm.progressStatus,
            },
          }),
          _c("div", { staticClass: "main-container" }, [
            _c("h2", { staticClass: "sl__h2 container-header" }, [
              _vm._v("\n      Great! "),
              _c("span", { staticClass: "company-name" }, [
                _vm._v(" " + _vm._s(_vm.company.name) + " "),
              ]),
              _vm._v(" has been added to your account.\n    "),
            ]),
            _c("p", { staticClass: "sl__p" }, [
              _vm._v(
                "\n      We have a couple more questions to understand " +
                  _vm._s(_vm.company.name) +
                  " more.\n    "
              ),
            ]),
            _c("div", { staticClass: "container-body" }, [
              _c("picture", [
                _c("source", {
                  attrs: {
                    srcset: require("@images/illustrations/stageline/stageline_company_creation_animation.webp"),
                    type: "image/webp",
                  },
                }),
                _c("img", {
                  staticClass: "animation",
                  attrs: {
                    src: require("@images/illustrations/stageline/stageline_company_creation_animation.gif"),
                    alt: "Company Created",
                  },
                }),
              ]),
            ]),
          ]),
          _c("button-container", {
            attrs: {
              "next-button-label": _vm.nextButtonLabel,
              "show-previous-button": _vm.showPreviousButton,
            },
            on: { next: _vm.next },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }